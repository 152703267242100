import * as React from 'react';

import {
  Box,
  Input,
  Flex,
  Text,
  Grid,
  useColors,
  Container,
  Icon,
  Button
} from '@churni/styleguide';
import * as querystring from 'querystring';

import { SEO } from '../components/SEO';

import { Line } from 'react-chartjs-2';
import {
  useLocation,
  useScenario,
  MarketingLayout,
  InputGroup
} from '@churni/common';

export default function GrowthCalculator() {
  const { location } = useLocation();
  const originalQS = querystring.parse(location.search.slice(1));

  const [months, setMonths] = React.useState(originalQS.months || 60);
  const [mode, setMode] = React.useState('mrr');
  const [activeScenario, setActiveScenario] = React.useState('scenario1');
  const [tableDisplayed, setTableDisplayed] = React.useState(false);
  const sharableInput = React.useRef(null);

  const qs = Object.entries(originalQS).reduce((acc, [key, entry]) => {
    const parsedNumber = Number(entry);
    if (entry === null || isNaN(parsedNumber)) {
      return { ...acc, [key]: null };
    }
    return { ...acc, [key]: parsedNumber };
  }, {});

  const scenario1 = useScenario({
    churnRate:
      qs.s1_churnrate !== null && qs.s1_churnrate !== undefined
        ? qs.s1_churnrate
        : 8,
    initialMRR:
      qs.s1_initialmrr !== null && qs.s1_initialmrr !== undefined
        ? qs.s1_initialmrr
        : 100000,
    newRevenue:
      qs.s1_newrev !== null && qs.s1_newrev !== undefined
        ? qs.s1_newrev
        : 10000,
    growthRate:
      qs.s1_growthrate !== null && qs.s1_growthrate !== undefined
        ? qs.s1_growthrate
        : 5,
    growthMode:
      qs.s1_growthmode !== null && qs.s1_growthmode !== undefined
        ? qs.s1_growthmode
        : 'flat',
    months
  });
  const scenario2 = useScenario({
    churnRate:
      qs.s2_churnrate !== null && qs.s2_churnrate !== undefined
        ? qs.s2_churnrate
        : 5.6,
    initialMRR:
      qs.s2_initialmrr !== null && qs.s2_initialmrr !== undefined
        ? qs.s2_initialmrr
        : 100000,
    newRevenue:
      qs.s2_newrev !== null && qs.s2_newrev !== undefined
        ? qs.s2_newrev
        : 10000,
    growthRate:
      qs.s2_growthrate !== null && qs.s2_growthrate !== undefined
        ? qs.s2_growthrate
        : 5,
    growthMode:
      qs.s2_growthmode !== null && qs.s2_growthmode !== undefined
        ? qs.s2_growthmode
        : 'flat',
    months
  });

  const sharableQS = querystring.stringify({
    s1_churnrate: scenario1.churnRate,
    s1_initialmrr: scenario1.initialMRR,
    s1_newrev: scenario1.newRevenue,
    s1_growthrate: scenario1.setGrowthRate,
    s1_growthmode: scenario1.growthMode,
    s2_churnrate: scenario2.churnRate,
    s2_initialmrr: scenario2.initialMRR,
    s2_newrev: scenario2.newRevenue,
    s2_growthrate: scenario2.setGrowthRate,
    s2_growthmode: scenario2.growthMode,
    months
  });

  const colors = useColors();

  const dataset = {
    labels: Object.keys(scenario1.projection),
    datasets: [
      {
        label: `Scenario ${scenario1.churnRate.toFixed(2)}% churn`,
        fill: false,
        lineTension: 0.1,
        backgroundColor: colors.primary,
        borderColor: colors.primary,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: colors.primary,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: colors.primary,
        pointHoverBorderColor: colors.primary,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: scenario1.projection.map(val =>
          mode === 'mrr' ? val.endingMRR : val.total
        ),
        fill: false
      },
      {
        label: `Scenario ${scenario2.churnRate.toFixed(2)}% churn`,
        fill: false,
        lineTension: 0.1,
        backgroundColor: colors.secondary,
        borderColor: colors.secondary,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: colors.secondary,
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: colors.secondary,
        pointHoverBorderColor: colors.secondary,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: scenario2.projection.map(val =>
          mode === 'mrr' ? val.endingMRR : val.total
        )
      }
    ]
  };

  return (
    <>
      <SEO
        title={'SaaS Growth Calculator'}
        titleTemplate={'%s'}
        image={'/churn-calculator.png'}
        description={
          'Forecast different growth scenarios for your SaaS business and estimate your future MRR (Monthly Recurring Revenue). Compare different churn rates to see how better churn management can help generate more revenue for your business.'
        }
      />
      <Box sx={{ width: '100%' }}>
        <MarketingLayout>
          <Container maxWidth={800} py={7}>
            <Box sx={{ textAlign: 'center' }} mb={5}>
              <Box>
                <Text variant={'display2'} as="h1">
                  SaaS Growth Calculator
                </Text>
              </Box>
              <Box mt={2}>
                <Text variant="subtitle2" color="text">
                  Forecast different growth scenarios for your SaaS business and
                  estimate your future MRR (Monthly Recurring Revenue). Compare
                  different churn rates to see how better churn management can
                  help generate more revenue for your business.
                </Text>
              </Box>
            </Box>
            <Box mb={8}>
              <Box>
                <Flex alignItems={'center'}>
                  <Box
                    mr={1}
                    sx={{
                      width: 20,
                      height: 20,
                      bg: 'primary',
                      borderRadius: 'radius'
                    }}
                  ></Box>
                  <Text variant="subtitle3">Scenario 1</Text>
                </Flex>
                <ScenarioSettings {...scenario1} />
              </Box>
              <Box mt={3}>
                <Flex alignItems={'center'}>
                  <Box
                    mr={1}
                    sx={{
                      width: 20,
                      height: 20,
                      bg: 'secondary',
                      borderRadius: 'radius'
                    }}
                  ></Box>
                  <Text variant="subtitle3">Scenario 2</Text>
                </Flex>

                <ScenarioSettings {...scenario2} />
              </Box>
              {/* </Grid> */}
            </Box>
            {/* </Box> */}

            <Box mb={1}>
              <Text>
                <Text
                  sx={{ cursor: 'pointer' }}
                  color={mode === 'mrr' ? 'text' : 'secondaryText'}
                  onClick={() => setMode('mrr')}
                >
                  MRR
                </Text>{' '}
                |{' '}
                <Text
                  sx={{ cursor: 'pointer' }}
                  color={mode === 'total' ? 'text' : 'secondaryText'}
                  onClick={() => setMode('total')}
                >
                  Total
                </Text>
              </Text>
            </Box>
            <LineChart dataset={dataset} mode={mode} />
            <Box width={150} mx={'auto'} sx={{ textAlign: 'center' }} mt={3}>
              <Text variant="overline2">Projection over</Text>
              <InputGroup
                type={'number'}
                label="Projection over Months"
                inputLabel={'months'}
                value={months}
                onChangeValue={val => setMonths(Number(val))}
              />
            </Box>

            <Box mt={4} sx={{ textAlign: 'center' }}>
              <Button
                variant={'primaryText'}
                icon={
                  tableDisplayed ? <Icon.ChevronUp /> : <Icon.ChevronDown />
                }
                onClick={() => setTableDisplayed(!tableDisplayed)}
              >
                {tableDisplayed ? 'Hide Calculation' : 'Show Calculation'}
              </Button>

              <Box
                elevation={1}
                py={2}
                px={2}
                mb={tableDisplayed ? 3 : 0}
                sx={{
                  cursor: 'pointer',
                  maxHeight: 500,
                  overflow: 'auto',
                  borderRadius: 'radius',

                  ...(tableDisplayed
                    ? {
                        transition: 'opacity 0.5s ease-out',
                        opacity: 1,
                        height: 'auto'
                      }
                    : {
                        opacity: 0,
                        height: 0,
                        overflow: 'hidden'
                      })
                }}
                onClick={() => {
                  setTableDisplayed(!tableDisplayed);
                }}
              >
                <Box>
                  <Text variant="body2">
                    <Text
                      sx={{ cursor: 'pointer' }}
                      onClick={e => {
                        e.stopPropagation();
                        setTableDisplayed(true);
                        setActiveScenario('scenario1');
                      }}
                      color={
                        activeScenario === 'scenario1'
                          ? 'text'
                          : 'secondaryText'
                      }
                    >
                      Scenario 1
                    </Text>{' '}
                    |{' '}
                    <Text
                      onClick={e => {
                        e.stopPropagation();
                        setTableDisplayed(true);
                        setActiveScenario('scenario2');
                      }}
                      sx={{ cursor: 'pointer' }}
                      color={
                        activeScenario === 'scenario2'
                          ? 'text'
                          : 'secondaryText'
                      }
                    >
                      Scenario 2
                    </Text>{' '}
                  </Text>
                  <Grid columns={[3, 5]} columnGap={[3, 1]}>
                    <Box>
                      <Text variant="overline2">Month</Text>
                    </Box>
                    <Box sx={{ display: ['none', 'block'] }}>
                      <Text variant="overline2">Current MRR</Text>
                    </Box>
                    <Box sx={{ display: ['none', 'block'] }}>
                      <Text variant="overline2">New Revenue</Text>
                    </Box>
                    <Box sx={{ display: ['none', 'block'] }}>
                      <Text variant="overline2">Churn Cost</Text>
                    </Box>
                    <Box>
                      <Text variant="overline2">Ending MRR</Text>
                    </Box>
                    {/* <Box>
                      <Text variant="overline2">Total</Text>
                    </Box> */}
                    {activeScenario === 'scenario1'
                      ? scenario1.projection.slice(1).map((val, key) => (
                          <>
                            <Box>{key + 1}</Box>
                            <Box sx={{ display: ['none', 'block'] }}>
                              {Math.ceil(val.startingMRR)}
                            </Box>
                            <Box sx={{ display: ['none', 'block'] }}>
                              {Math.ceil(val.newRevenue)}
                            </Box>
                            <Box sx={{ display: ['none', 'block'] }}>
                              {Math.ceil(val.churnedRevenue)}
                            </Box>
                            <Box>{Math.ceil(val.endingMRR)}</Box>
                            {/* <Box>{Math.ceil(val.total)}</Box> */}
                          </>
                        ))
                      : scenario2.projection.slice(1).map((val, key) => (
                          <>
                            <Box>{key + 1}</Box>
                            <Box sx={{ display: ['none', 'block'] }}>
                              {Math.ceil(val.startingMRR)}
                            </Box>
                            <Box sx={{ display: ['none', 'block'] }}>
                              {Math.ceil(val.newRevenue)}
                            </Box>
                            <Box sx={{ display: ['none', 'block'] }}>
                              {Math.ceil(val.churnedRevenue)}
                            </Box>
                            <Box>{Math.ceil(val.endingMRR)}</Box>
                            {/* <Box>{Math.ceil(val.total)}</Box> */}
                          </>
                        ))}
                  </Grid>
                </Box>
              </Box>

              <Box>
                <Box>
                  <Text>Share this</Text>
                </Box>
                <Box>
                  <Input
                    sx={{ cursor: 'pointer', bg: 'muted' }}
                    ref={sharableInput}
                    onClick={() => {
                      if (sharableInput.current) {
                        sharableInput.current.select();
                        document.execCommand('copy');
                      }
                    }}
                    value={`https://www.churni.io/growth-calculator?${sharableQS}`}
                  ></Input>
                </Box>
              </Box>
            </Box>
          </Container>
        </MarketingLayout>
      </Box>
    </>
  );
}

export function ScenarioSettings(props: {
  initialMRR: number;
  churnRate: number;
  newRevenue: number;
  growthRate: number;
  growthMode: 'flat' | 'rate';
  setInitialMRR: (val: number) => void;
  setChurnRate: (val: number) => void;
  setNewRevenue: (val: number) => void;
  setGrowthRate: (val: number) => void;
  setGrowthMode: (val: number) => void;
}): React.ReactElement {
  const {
    initialMRR,
    churnRate,
    newRevenue,
    growthRate,
    growthMode,
    setInitialMRR,
    setChurnRate,
    setNewRevenue,
    setGrowthMode,
    setGrowthRate
  } = props;

  return (
    <Grid columns={3} columnGap={[1, 3]}>
      <Box>
        <Text variant="overline2">Current MRR</Text>
        <InputGroup
          inputLabel={'$'}
          type="number"
          value={initialMRR}
          onChangeValue={val => setInitialMRR(Number(val))}
        />
      </Box>
      <Box>
        <Text variant="overline2">Churn Rate</Text>
        <InputGroup
          inputLabel={'% / mo'}
          type={'number'}
          value={churnRate}
          onChangeValue={val => setChurnRate(Number(val))}
        />
      </Box>
      <Box>
        <Flex>
          <Box>
            <Text variant="overline2" sx={{ whiteSpace: 'nowrap' }}>
              Growth (
              <Text
                color={growthMode === 'flat' ? 'text' : 'secondaryText'}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setGrowthMode('flat');
                }}
              >
                $
              </Text>
              {' | '}
              <Text
                color={growthMode === 'rate' ? 'text' : 'secondaryText'}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setGrowthMode('rate');
                }}
              >
                %
              </Text>
              )
            </Text>
          </Box>
        </Flex>
        {growthMode === 'flat' ? (
          <InputGroup
            inputLabel={'$ / mo'}
            type={'number'}
            value={newRevenue}
            onChangeValue={val => setNewRevenue(Number(val))}
          />
        ) : (
          <InputGroup
            inputLabel={'% / mo'}
            type={'number'}
            value={growthRate}
            onChangeValue={val => setGrowthRate(Number(val))}
          />
        )}
      </Box>
    </Grid>
  );
}

export function LineChart(props: {
  dataset: any;
  onClick: (index: number) => void;
  mode: 'mrr' | 'total';
}): React.ReactElement {
  const { dataset, onClick, mode } = props;

  return (
    <Box>
      <Line
        onElementsClick={elements => {
          if (elements.length) {
            const index = elements[0]._index;
            onClick(index);
          }
        }}
        data={dataset}
        legend={{
          position: 'bottom',
          fullWidth: true
        }}
        options={{
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString:
                    mode === 'total'
                      ? 'Total Revenue'
                      : 'Monthly Reccuring Revenue'
                }
              }
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'Months'
                }
              }
            ]
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem: any, data: any) => {
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const currentValue = dataset.data[tooltipItem.index];

                return `$${currentValue.toLocaleString('en')}`;
              },
              title: (tooltipItem: any, data: any) =>
                `Month ${data.labels[tooltipItem[0].index]}`
            }
          }
        }}
      />
    </Box>
  );
}
